import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../stores/Store';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { Textbox, CustomSelect, CashflowGraph, RunwayGauge, BurnRateGraph, Calender } from '../../../components';
import CloseArchive from '../../../assets/images/closeArchive.svg';
import Filter from '../../../assets/images/Filter.svg';
import FilterDown from '../../../assets/images/FilterDown.svg';
import LeftArrow from '../../../assets/images/leftArrow.svg';
import RightArrow from '../../../assets/images/rightArrow.svg';
import AddImg from '../../../assets/images/add.png';
import DeleteSVG from '../../../assets/images/DeleteSVG.svg';
import EditSVG from '../../../assets/images/EditSVG.svg';
import CloseCard from '../../../assets/images/CloseTask.svg';
var mobile = require('is-mobile');

const Reports = observer(({ compId, back }) => {
    const store = useStore();
    const navigate = useNavigate();
    const [, updateState] = useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    const graphSectionRef = useRef();
    const overViewSectionref = useRef();
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [overViewDimensions, setOverviewDimensions] = useState({ width: 0, height: 0 })
    const [OnHover, setOnHover] = useState(false);
    const [Filter, setFilter] = useState('Yearly');
    const [Onset, setOnset] = useState(0);
    const [Company, setCompany] = useState({});
    const [IsPredicted, setIsPredicted] = useState(false);
    const [IsLabelOn, setIsLabelOn] = useState(false);
    const [isToggled, setIsToggled] = useState(false);
    const [LegendsFilter, setLegendsFilter] = useState([]);
    const [runwayData, setRunway] = useState(0);
    const [ForecastValues, setForecastValues] = useState([]);
    const [ProphetValues, setProphetData] = useState([]);
    const [Values, setValues] = useState([]);
    const [LineChartData, setLineChartData] = useState([]);
    const [BarChartData, setBarChartData] = useState({
        CurrentMonth: {},
        PreviousMonth: {},
        Date: new Date(),
        PreviousDate: new Date()
    });
    const [ViewPredicted, setViewPredicted] = useState(false);

    useEffect(() => {
        getCompaniesData();
        const updateDimensions = () => {
            if (graphSectionRef.current) {
                setDimensions({
                    width: graphSectionRef.current.offsetWidth,
                    height: graphSectionRef.current.offsetHeight,
                });
            }
            if (overViewSectionref.current) {
                setOverviewDimensions({
                    width: overViewSectionref.current.offsetWidth,
                    height: overViewSectionref.current.offsetHeight,
                });
            }
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, []);
    const getCompaniesData = async () => {
        store.setLoading(true);
        try {
            store.setLoading(true);
            let comp = await store.getCompanyByID(compId);
            if (typeof comp === "string" || !comp) {
                alert('Error please try again!');
                store.setLoading(false);
                return;
            };
            setValues(comp.Values);
            setCompany(comp);

            let metricWithForecast = [];
            let metricWithPredictedForecast = [];

            for (let i = 0; i < comp.Values.length; i++) {
                let metric = getTotals(comp.Values[i]);
                if (metric.Type === 'Actual') {
                    metricWithForecast.push(metric);
                    metricWithPredictedForecast.push(metric);
                } else if (metric.Type === 'Forecast') {
                    metricWithForecast.push(metric);
                } else if (metric.Type === 'Predicted_Forecast') {
                    metric.Type = 'Forecast';
                    metricWithPredictedForecast.push(metric);
                }
            };
            // let metricWithForecast = comp.Values.filter(metric => metric.Type === 'Actual' || metric.type === 'Forecast')
            // let metricWithPredictedForecast = comp.Values.filter(metric => metric.Type === 'Actual' || metric.type === 'Predicted_Forecast')

            // Line Chart Data Preparation;
            let lineChartData = [];
            if (!ViewPredicted) {
                lineChartData = prepareLineChartData(metricWithForecast);
            } else if (ViewPredicted) {
                lineChartData = prepareLineChartData(metricWithPredictedForecast);
            }
            setLineChartData(lineChartData);

            // Bar Charts Data Preparation;
            let onset = Onset;

            if (!ViewPredicted) {
                if (Filter === 'Monthly') {
                    prepareMonthlyBarChartData(metricWithForecast, onset);
                } else if (Filter === 'Yearly') {
                    prepareYearlyBarChartData(metricWithForecast, onset);
                } else if (Filter === 'Entire') {
                    prepareAllTimeBarChartData(metricWithForecast);
                };
            } else if (ViewPredicted) {
                if (Filter === 'Monthly') {
                    prepareMonthlyBarChartData(metricWithPredictedForecast, onset);
                } else if (Filter === 'Yearly') {
                    prepareYearlyBarChartData(metricWithPredictedForecast, onset);
                } else if (Filter === 'Entire') {
                    prepareAllTimeBarChartData(metricWithPredictedForecast);
                }
            }
            setForecastValues(metricWithForecast);
            setProphetData(metricWithPredictedForecast);
            forceUpdate();
            store.setLoading(false);
        } catch (e) {
            alert('Error loading companies data.', e)
            store.setLoading(false);
            return
        }
    };
    const openHover = () => {
        setOnHover(true);
        forceUpdate();
    };
    const closeHover = () => {
        setOnHover(false);
        forceUpdate();
    };
    const handleToggle = () => {
        setIsToggled(!isToggled);
    };
    const openLabelClicked = () => {
        setIsLabelOn(true);
        forceUpdate();
    };
    const closeLabelClicked = () => {
        setIsLabelOn(false);
        forceUpdate();
    };
    const legendClicked = (e) => {
        let legends = [...LegendsFilter];
        let id = e.currentTarget.id;

        if (legends.includes(id)) {
            legends = legends.filter((legendId) => legendId !== id);
        } else {
            legends.push(id);
        }
        setLegendsFilter(legends);
        forceUpdate();
    };
    //New modified Code
    
    const getTotals = (data) => {
        if (!data || typeof data !== "object") {
            throw new Error("Invalid data object");
        }

        const getCategoryTotal = (categoryObj) => {
            if (!categoryObj) return 0;

            return Object.values(categoryObj)
                .flat()
                .reduce((acc, item) => acc + (item.Amount || 0), 0);
        };

        const burnrateTotal = getCategoryTotal(data.Burnrate || {});
        const cashflowTotal = getCategoryTotal(data.Cashflow || {});
        const costsTotal = getCategoryTotal(data.Costs || {});
        const expensesTotal = getCategoryTotal(data.Expenses || {});
        const incomeTotal = getCategoryTotal(data.Income || {});

        return {
            Date: new Date(data.Date),
            Type: data.Type,
            Burnrate: burnrateTotal,
            Cashflow: cashflowTotal,
            Costs: costsTotal,
            Expenses: expensesTotal,
            Income: incomeTotal,
        };
    };
    const prepareLineChartData = (data) => {
        let finalArr = [];
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            let newObj = {
                Income: item.Income,
                Expenses: item.Expenses,
                Cashflow: item.Cashflow,
                Burnrate: item.Burnrate,
                Type: item.Type,
                date: new Date(item.Date)
            }
            finalArr.push(newObj)
        }
        return finalArr;
    };
    const prepareMonthlyBarChartData = (acts, onset = Onset) => {
        let currentDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth() + onset;
        let previousMonth = currentMonth - 1;

        let previousYear = currentYear;
        if (previousMonth < 0) {
            previousMonth += 12;
            previousYear -= 1;
        }

        let currentDateUpdated = new Date(currentYear, currentMonth, currentDate.getDate());
        let previousDate = new Date(previousYear, previousMonth, currentDate.getDate());

        let currentMonthData = acts.filter((item) => {
            const objDate = new Date(item.Date).getMonth();
            const objYear = new Date(item.Date).getFullYear();
            return objDate === currentDateUpdated.getMonth() && objYear === currentDateUpdated.getFullYear();
        });

        let prevMonthData = acts.filter((item) => {
            const objDate = new Date(item.Date).getMonth();
            const objYear = new Date(item.Date).getFullYear();
            return objDate === previousDate.getMonth() && objYear === previousDate.getFullYear();
        });

        let CurrentMonth = {
            Type: 'Yearly',
            Income: 0,
            Expenses: 0,
            Burnrate: 0,
            Cashflow: 0,
            ForecastIncome: 0,
            ForecastExpenses: 0,
            ForecastCashflow: 0,
            ForecastBurnrate: 0
        }
        let PreviousMonth = {
            Type: 'Yearly',
            Income: 0,
            Expenses: 0,
            Burnrate: 0,
            Cashflow: 0,
            ForecastIncome: 0,
            ForecastExpenses: 0,
            ForecastCashflow: 0,
            ForecastBurnrate: 0
        }

        for (let i = 0; i < currentMonthData.length; i++) {
            let item = currentMonthData[i];
            if (item.Type === 'Forecast') {
                CurrentMonth.Type = 'Yearly';
                CurrentMonth.ForecastBurnrate += item.Burnrate || 0;
                CurrentMonth.ForecastCashflow += item.Cashflow || 0;
                CurrentMonth.ForecastExpenses += item.Expenses || 0;
                CurrentMonth.ForecastIncome += item.Income || 0;
            } else if (item.Type === 'Actual') {
                CurrentMonth.Type = 'Yearly';
                CurrentMonth.Income += item.Income || 0;
                CurrentMonth.Expenses += item.Expenses || 0;
                CurrentMonth.Cashflow += item.Cashflow || 0;
                CurrentMonth.Burnrate += item.Burnrate || 0;
            }
        };
        for (let i = 0; i < prevMonthData.length; i++) {
            let item = prevMonthData[i];
            if (item.Type === 'Forecast') {
                PreviousMonth.Type = 'Yearly';
                PreviousMonth.ForecastBurnrate += item.Burnrate || 0;
                PreviousMonth.ForecastCashflow += item.Cashflow || 0;
                PreviousMonth.ForecastCosts += item.Costs || 0;
                PreviousMonth.ForecastExpenses += item.Expenses || 0;
                PreviousMonth.ForecastIncome += item.Income || 0;
            } else if (item.Type === 'Actual') {
                PreviousMonth.Type = 'Yearly';
                PreviousMonth.Income += item.Income || 0;
                PreviousMonth.Expenses += item.Expenses || 0;
                PreviousMonth.Cashflow += item.Cashflow || 0;
                PreviousMonth.Burnrate += item.Burnrate || 0;
            }
        };
        setBarChartData({
            CurrentMonth: CurrentMonth,
            PreviousMonth: PreviousMonth,
            Date: currentDateUpdated,
            PreviousDate: previousDate
        });
        forceUpdate();
        store.setLoading(false);

    };
    const prepareYearlyBarChartData = (data, onset = Onset) => {
        store.setLoading(true);
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear() + onset;
        let previousYear = currentYear - 1;
        let currentDateUpdated = new Date(currentYear, currentDate.getMonth(), currentDate.getDate());
        let previousDate = new Date(previousYear, currentDate.getMonth(), currentDate.getDate());

        const currentYearData = data.filter(item => {
            const itemDate = new Date(item.Date);
            return itemDate.getFullYear() === currentYear;
        });
        const previousYearData = data.filter(item => {
            const itemDate = new Date(item.Date);
            return itemDate.getFullYear() === previousYear;
        });

        let CurrentMonth = {
            Type: 'Yearly',
            Income: 0,
            Expenses: 0,
            Burnrate: 0,
            Cashflow: 0,
            ForecastIncome: 0,
            ForecastExpenses: 0,
            ForecastCashflow: 0,
            ForecastBurnrate: 0
        }
        let PreviousMonth = {
            Type: 'Yearly',
            Income: 0,
            Expenses: 0,
            Burnrate: 0,
            Cashflow: 0,
            ForecastIncome: 0,
            ForecastExpenses: 0,
            ForecastCashflow: 0,
            ForecastBurnrate: 0
        }

        for (let i = 0; i < currentYearData.length; i++) {
            let item = currentYearData[i];
            if (item.Type === 'Forecast') {
                CurrentMonth.Type = 'Yearly';
                CurrentMonth.ForecastBurnrate += item.Burnrate || 0;
                CurrentMonth.ForecastCashflow += item.Cashflow || 0;
                CurrentMonth.ForecastExpenses += item.Expenses || 0;
                CurrentMonth.ForecastIncome += item.Income || 0;
            } else if (item.Type === 'Actual') {
                CurrentMonth.Type = 'Yearly';
                CurrentMonth.Income += item.Income || 0;
                CurrentMonth.Expenses += item.Expenses || 0;
                CurrentMonth.Cashflow += item.Cashflow || 0;
                CurrentMonth.Burnrate += item.Burnrate || 0;
            }
        };
        for (let i = 0; i < previousYearData.length; i++) {
            let item = previousYearData[i];
            if (item.Type === 'Forecast') {
                PreviousMonth.Type = 'Yearly';
                PreviousMonth.ForecastBurnrate += item.Burnrate || 0;
                PreviousMonth.ForecastCashflow += item.Cashflow || 0;
                PreviousMonth.ForecastCosts += item.Costs || 0;
                PreviousMonth.ForecastExpenses += item.Expenses || 0;
                PreviousMonth.ForecastIncome += item.Income || 0;
            } else if (item.Type === 'Actual') {
                PreviousMonth.Type = 'Yearly';
                PreviousMonth.Income += item.Income || 0;
                PreviousMonth.Expenses += item.Expenses || 0;
                PreviousMonth.Cashflow += item.Cashflow || 0;
                PreviousMonth.Burnrate += item.Burnrate || 0;
            }
        };
        setBarChartData({
            CurrentMonth: CurrentMonth,
            PreviousMonth: PreviousMonth,
            Date: currentDateUpdated,
            PreviousDate: previousDate
        });
        forceUpdate();
        store.setLoading(false);
    };
    const prepareAllTimeBarChartData = (data) => {
        let currentYearObj = BarChartData.CurrentMonth;
        let previousYearObj = BarChartData.PreviousMonth;

        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if (item.Type === 'Forecast') {
                currentYearObj.Type = 'Yearly';
                currentYearObj.ForecastBurnrate += item.Burnrate || 0;
                currentYearObj.ForecastCashflow += item.Cashflow || 0;
                currentYearObj.ForecastExpenses += item.Expenses || 0;
                currentYearObj.ForecastIncome += item.Income || 0;
            } else if (item.Type === 'Actual') {
                currentYearObj.Type = 'Yearly';
                currentYearObj.Income += item.Income || 0;
                currentYearObj.Expenses += item.Expenses || 0;
                currentYearObj.Cashflow += item.Cashflow || 0;
                currentYearObj.Burnrate += item.Burnrate || 0;
            }
        };
        setBarChartData({
            CurrentMonth: currentYearObj,
            PreviousMonth: previousYearObj
        });
    };
    const formatDate = (date) => {
        if (date === undefined) {
            date = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)
        }
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const year = date.getFullYear();
        const month = date.getMonth();

        if (Filter === 'Month') {
            return `${monthNames[month]} ${year}`;
        } else if (Filter === 'Yearly') {
            return year.toString();
        } else {
            return '';
        }
    };
    const handleMoveForwardClicked = () => {
        let onset = Onset + 1;
        let acts;

        if (IsPredicted) {
            acts = ProphetValues;
        } else {
            acts = ForecastValues;
        };
        setOnset(onset);

        if (Filter === 'Monthly') {
            prepareMonthlyBarChartData(acts, onset);
        } else if (Filter === 'Yearly') {
            prepareYearlyBarChartData(acts, onset);
        } else if (Filter === 'Entire') {
            prepareAllTimeBarChartData(acts);
        };
        forceUpdate();
    };
    const handleMoveBackClicked = () => {
        let onset = Onset - 1;
        let acts;

        if (IsPredicted) {
            acts = ProphetValues;
        } else {
            acts = ForecastValues;
        }
        setOnset(onset);

        if (Filter === 'Monthly') {
            prepareMonthlyBarChartData(acts, onset);
        } else if (Filter === 'Yearly') {
            prepareYearlyBarChartData(acts, onset);
        } else if (Filter === 'Entire') {
            prepareAllTimeBarChartData(acts);
        }
        forceUpdate();
    };
    const changeFilter = (e) => {
        let filter = e.currentTarget.id;
        let acts;

        if (IsPredicted) {
            acts = ProphetValues;
        } else {
            acts = ForecastValues;
        };

        let onset = 0;
        setOnset(onset);
        if (filter === 'Monthly') {
            setFilter(filter);
            prepareMonthlyBarChartData(acts, onset);
        } else if (filter === 'Yearly') {
            setFilter(filter);
            prepareYearlyBarChartData(acts, onset);
        } else if (filter === 'Entire') {
            setFilter(filter);
            prepareAllTimeBarChartData(acts);
        };
        forceUpdate();
    };
    const viewPredicted = () => {
        if (!IsPredicted) {
            setIsPredicted(true);
            forceUpdate();
        } else {
            setIsPredicted(false);
            forceUpdate();
        }

        let onset = Onset;
        let acts;

        if (IsPredicted) {
            acts = ForecastValues;
        } else {
            acts = ProphetValues;
        };

        let lineChartData = prepareLineChartData(acts);

        setLineChartData(lineChartData);
        if (Filter === 'Monthly') {
            prepareMonthlyBarChartData(acts, onset);
        } else if (Filter === 'Yearly') {
            prepareYearlyBarChartData(acts, onset);
        } else if (Filter === 'Entire') {
            prepareAllTimeBarChartData(acts);
        }

        forceUpdate();
    };
    function formatmonthlyDate(date) {
        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const month = months[date.getMonth()];
        const year = date.getFullYear();
        return `${month} ${year}`;
    };
    const ReturnToReports = ()=>{
        back('Reports');
        forceUpdate();
    };
    const income = BarChartData.CurrentMonth.Income;
    const expenses = BarChartData.CurrentMonth.Expenses;

    const profit = income - expenses;
    if (mobile()) {
        return (
            <div >
            </div>
        );
    } else {
        return (
            <div className='OverViewPage'>
                <div className='income-loss-container'>
                    <div style={{ width: '30%', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img src={LeftArrow} alt='' style={{ cursor: 'pointer' , marginRight:'4%'}} onClick={ReturnToReports}/>
                        <div className='ProfitLossContrainer' style={{ backgroundColor: profit > 0 ? '#213038' : '#A62626' }} onMouseEnter={openLabelClicked} onMouseLeave={closeLabelClicked}>
                            <p>{profit >= 0 ? 'R ' : '-R '} {Math.abs(profit).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                            {(IsLabelOn) ? <p className='labelPlaceholder'>{profit > 0 ? 'Profit' : 'Loss'}</p> : null}
                        </div>
                    </div>
                    <div className='ViewPredictedDataSect'>
                        <p>Predicted Forecast</p>
                        <label className="switch">
                            <input type="checkbox" checked={IsPredicted} onChange={viewPredicted} />
                            <span className="slider round"></span>
                        </label>
                    </div>
                    <div className='period-section' onMouseEnter={openHover} onMouseLeave={closeHover}>
                        <p>{Filter}</p>
                        {(OnHover) ?
                            <>
                                <div className='period-pointer' />
                                <div className='period-container'>
                                    <div className='period-container-options' id='Monthly' style={{ backgroundColor: Filter === 'Monthly' ? '#D5922D' : '' }} onClick={changeFilter}>Monthly</div>
                                    <div className='period-container-options' id='Yearly' style={{ backgroundColor: Filter === 'Yearly' ? '#D5922D' : '' }} onClick={changeFilter}>Yearly</div>
                                    <div className='period-container-options' id='Entire' style={{ backgroundColor: Filter === 'Entire' ? '#D5922D' : '' }} onClick={changeFilter}>Entire</div>
                                </div>
                            </> : null}
                    </div>
                </div>
                <div className='overviewCharts'>
                    <div className='newHorizontalChartSection'>
                        <div className='filteredDateandTotalsSect' style={{ justifyContent: Filter === 'Entire' ? 'center' : 'space-between' }}>
                            {(Filter !== 'Entire') ?
                                <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackClicked} />
                                : null}
                            <p>Revenue {Filter === 'Yearly' ? `${formatDate(BarChartData.Date || '00/00/0000')} ` : Filter === 'Monthly' ? `${formatmonthlyDate(BarChartData.Date)}` : ''} - <span style={{ color: '#D4912D' }}>
                                R{BarChartData.CurrentMonth.Income !== undefined ? BarChartData.CurrentMonth.Income.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                            </span></p>
                            {(Filter !== 'Entire') ?
                                <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForwardClicked} />
                                : null}
                        </div>
                        <div className='horizontalChartSect' ref={graphSectionRef}>
                            <CashflowGraph Actual={BarChartData} currentAct={BarChartData.CurrentMonth.Income} currentFore={BarChartData.CurrentMonth.ForecastIncome} previousAct={BarChartData.PreviousMonth.Income} previousFore={BarChartData.PreviousMonth.ForecastIncome} width={dimensions.width} height={dimensions.height - 15} date={formatDate(BarChartData.Date)} prevDate={formatDate(BarChartData.PreviousDate)} type='Income' />
                        </div>
                        <div className='legendsSection'>
                            <div className='actual-Label' /><p>Actual</p>
                            <div className='forecast-Label' /><p>Forecast</p>
                            <div className='difference-Label' /><p>Difference</p>
                        </div>
                    </div>
                    <div className='newHorizontalChartSection'>
                        <div className='filteredDateandTotalsSect' style={{ justifyContent: Filter === 'Entire' ? 'center' : 'space-between' }}>
                            {(Filter !== 'Entire') ?
                                <img src={LeftArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveBackClicked} />
                                : null}
                            <p>Expenses {Filter === 'Yearly' ? `${formatDate(BarChartData.Date || '00/00/0000')} ` : Filter === 'Monthly' ? `${formatmonthlyDate(BarChartData.Date)}` : ''} - <span style={{ color: '#D4912D' }}>
                                R{BarChartData.CurrentMonth.Expenses !== undefined ? BarChartData.CurrentMonth.Expenses.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                            </span></p>
                            {(Filter !== 'Entire') ?
                                <img src={RightArrow} alt='' style={{ cursor: 'pointer' }} onClick={handleMoveForwardClicked} />
                                : null}
                        </div>
                        <div className='horizontalChartSect' ref={graphSectionRef}>
                            <CashflowGraph Actual={BarChartData} currentAct={BarChartData.CurrentMonth.Expenses} currentFore={BarChartData.CurrentMonth.ForecastExpenses} previousAct={BarChartData.PreviousMonth.Expenses} previousFore={BarChartData.PreviousMonth.ForecastExpenses} width={dimensions.width} height={dimensions.height - 15} date={formatDate(BarChartData.Date)} prevDate={formatDate(BarChartData.PreviousDate)} type='Expenses' />
                        </div>
                        <div className='legendsSection'>
                            <div className='actual-Label' /><p>Actual</p>
                            <div className='forecast-Label' /><p>Forecast</p>
                            <div className='difference-Label' /><p>Difference</p>
                        </div>
                    </div>
                    <div className='newOverViewcard'>
                        <div className='runwaySection'>
                            <div className='gaugeContainer'>
                                <RunwayGauge style={{ marginTop: '6%', width: '165px' }} width={200} height={200} value={runwayData} />
                                <p>Runway: {runwayData || 0} Months</p>
                            </div>
                            <div className='forecastToogleSwitch'>
                                <p>Forecast</p>
                                <label className="switch">
                                    <input type="checkbox" checked={isToggled} onChange={handleToggle} />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <div className='chartContainer' ref={overViewSectionref}>
                            <BurnRateGraph width={overViewDimensions.width} height={overViewDimensions.height} data={LineChartData} isForecast={isToggled} exclude={LegendsFilter} />
                        </div>
                        <div className='availLegendsContainer'>
                            <div className='income-Label' style={{ backgroundColor: LegendsFilter.includes('Income') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Income' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Income') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Income') ? 'line-through' : 'none', cursor: 'pointer' }} id='Income' onClick={legendClicked}>Revenue</p>
                            <div className='expense-Label' style={{ backgroundColor: LegendsFilter.includes('Expenses') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Expenses' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Expenses') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Expenses') ? 'line-through' : 'none', cursor: 'pointer' }} id='Expenses' onClick={legendClicked}>Expenses</p>
                            <div className='burnrate-Label' style={{ backgroundColor: LegendsFilter.includes('Burnrate') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Burnrate' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Burnrate') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Burnrate') ? 'line-through' : 'none', cursor: 'pointer' }} id='Burnrate' onClick={legendClicked}>Burnrate</p>
                            <div className='cashflow-Label' style={{ backgroundColor: LegendsFilter.includes('Cashflow') ? 'grey' : '', cursor: 'pointer' }} onClick={legendClicked} id='Cashflow' />
                            <p style={{ marginRight: '5%', WebkitTextStroke: LegendsFilter.includes('Cashflow') ? '0px #213038' : 'none', textDecoration: LegendsFilter.includes('Cashflow') ? 'line-through' : 'none', cursor: 'pointer' }} id='Cashflow' onClick={legendClicked}>Cashflow</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
});

export default Reports;